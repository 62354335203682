<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="4">
                                Detail Perusahaan : {{data_perusahaan.p_nama}}
                            </CCol>
                            <CCol md="8" class="text-right">
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody> 
                        <template>
							<CTabs variant="tabs" class="a-black" :active-tab="0" style="color: #333;">
                                <CTab title="Data Perusahaan" v-if="data_perusahaan.tipe_pemohon_id != 2">
                                    <div class="mt-3">
                                        <template>
                                            <CTabs variant="pills" :active-tab="0">
                                                <CTab title="Profile">
                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                        <tbody>
                                                            <tr v-for="(value_data_profile_perusahaan, key_data_profile_perusahaan) in data_profile_perusahaan" :key="key_data_profile_perusahaan">
                                                                <th width="350">{{key_data_profile_perusahaan}}</th>
                                                                <td>{{value_data_profile_perusahaan}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CTab>
                                                <CTab title="Data Infestasi">
                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                        <tbody>
                                                            <tr v-for="(value_data_infestasi, key_data_infestasi) in data_infestasi" :key="key_data_infestasi">
                                                                <th width="350">{{key_data_infestasi}}</th>
                                                                <td>{{value_data_infestasi}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CTab>
                                                <CTab title="Pemegang Saham">
                                                    <div>
                                                        <CCard class="mb-0" v-for="(value_data_pemegang_saham, key_data_pemegang_saham) in data_pemegang_saham" v-bind:key="key_data_pemegang_saham">
                                                            <CButton
                                                                block
                                                                color="link"
                                                                class="text-left shadow-none card-header"
                                                                @click="accordion = accordion === key_data_pemegang_saham ? false : key_data_pemegang_saham"
                                                            >
                                                                <h5 class="m-0">Pemegang Saham #{{key_data_pemegang_saham+1}}</h5>
                                                            </CButton>
                                                            <CCollapse :show="accordion === key_data_pemegang_saham">
                                                                <CCardBody>
                                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                        <tbody>
                                                                            <tr v-for="(value_data_pemegang_saham_data, key_data_pemegang_saham_val) in value_data_pemegang_saham" :key="key_data_pemegang_saham_val">
                                                                                <th width="350">{{key_data_pemegang_saham_val}}</th>
                                                                                <td>{{value_data_pemegang_saham_data}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </CCardBody>
                                                            </CCollapse>
                                                        </CCard>
                                                    </div>
                                                </CTab>
                                                <CTab title="Penanggung Jawab">
                                                    <div>
                                                        <CCard class="mb-0" v-for="(value_penanggung_jawab, key_penanggung_jawab) in data_penanggung_jawab" v-bind:key="key_penanggung_jawab">
                                                            <CButton
                                                                block
                                                                color="link"
                                                                class="text-left shadow-none card-header"
                                                                @click="accordion = accordion === key_penanggung_jawab ? false : key_penanggung_jawab"
                                                            >
                                                                <h5 class="m-0">Penanggung Jawab #{{key_penanggung_jawab+1}}</h5>
                                                            </CButton>
                                                            <CCollapse :show="accordion === key_penanggung_jawab">
                                                                <CCardBody>
                                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                        <tbody>
                                                                            <tr v-for="(value_penanggung_jawab_data, key_penanggung_jawab_data) in value_penanggung_jawab" :key="key_penanggung_jawab_data">
                                                                                <th width="350">{{key_penanggung_jawab_data}}</th>
                                                                                <td>{{value_penanggung_jawab_data}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </CCardBody>
                                                            </CCollapse>
                                                        </CCard>
                                                    </div>
                                                </CTab>
                                                <CTab title="Legalitas">
                                                    <div>
                                                        <CCard class="mb-0" v-for="(value_legalitas, key_legalitas) in data_legalitas" v-bind:key="key_legalitas">
                                                            <CButton
                                                                block
                                                                color="link"
                                                                class="text-left shadow-none card-header"
                                                                @click="accordion = accordion === key_legalitas ? false : key_legalitas"
                                                            >
                                                                <h5 class="m-0">Legalitas #{{key_legalitas+1}}</h5>
                                                            </CButton>
                                                            <CCollapse :show="accordion === key_legalitas">
                                                                <CCardBody>
                                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                        <tbody>
                                                                            <tr v-for="(value_legalitas_data, key_legalitas_data) in value_legalitas" :key="key_legalitas_data">
                                                                                <th width="350">{{key_legalitas_data}}</th>
                                                                                <td>{{value_legalitas_data}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </CCardBody>
                                                            </CCollapse>
                                                        </CCard>
                                                    </div>
                                                </CTab>
                                                <CTab title="RPTKA Jabatan"> </CTab>
                                                <CTab title="RPTKA Negara">
                                                    <div>
                                                        <CCard class="mb-0" v-for="(value_rptka_negara, key_rptka_negara) in data_rptka_negara" v-bind:key="key_rptka_negara">
                                                            <CButton
                                                                block
                                                                color="link"
                                                                class="text-left shadow-none card-header"
                                                                @click="accordion = accordion === key_rptka_negara ? false : key_rptka_negara"
                                                            >
                                                                <h5 class="m-0">Negara #{{key_rptka_negara+1}}</h5>
                                                            </CButton>
                                                            <CCollapse :show="accordion === key_rptka_negara">
                                                                <CCardBody>
                                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                        <tbody>
                                                                            <tr v-for="(value_rptka_negara_data, key_rptka_negara_data) in value_rptka_negara" :key="key_rptka_negara_data">
                                                                                <th width="350">{{key_rptka_negara_data}}</th>
                                                                                <td>{{value_rptka_negara_data}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </CCardBody>
                                                            </CCollapse>
                                                        </CCard>
                                                    </div>
                                                </CTab>
                                                <CTab title="RPTKA Lokasi">
                                                    <div>
                                                        <CCard class="mb-0" v-for="(value_rptka_lokasi, key_rptka_lokasi) in data_rptka_lokasi" v-bind:key="key_rptka_lokasi">
                                                            <CButton
                                                                block
                                                                color="link"
                                                                class="text-left shadow-none card-header"
                                                                @click="accordion = accordion === key_rptka_lokasi ? false : key_rptka_lokasi"
                                                            >
                                                                <h5 class="m-0">Lokasi #{{key_rptka_lokasi+1}}</h5>
                                                            </CButton>
                                                            <CCollapse :show="accordion === key_rptka_lokasi">
                                                                <CCardBody>
                                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                        <tbody>
                                                                            <tr v-for="(value_rptka_lokasi_data, key_rptka_lokasi_data) in value_rptka_lokasi" :key="key_rptka_lokasi_data">
                                                                                <th width="350">{{key_rptka_lokasi_data}}</th>
                                                                                <td>{{value_rptka_lokasi_data}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </CCardBody>
                                                            </CCollapse>
                                                        </CCard>
                                                    </div>
                                                </CTab>
                                                <CTab title="Data DNI">
                                                    <div>
                                                        <CCard class="mb-0" v-for="(value_data_dni, key_data_dni) in data_data_dni" v-bind:key="key_data_dni">
                                                            <CButton
                                                                block
                                                                color="link"
                                                                class="text-left shadow-none card-header"
                                                                @click="accordion = accordion === key_data_dni ? false : key_data_dni"
                                                            >
                                                                <h5 class="m-0">Data DNI #{{key_data_dni+1}}</h5>
                                                            </CButton>
                                                            <CCollapse :show="accordion === key_data_dni">
                                                                <CCardBody>
                                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                        <tbody>
                                                                            <tr v-for="(value_data_dni_data, key_data_dni_data) in value_data_dni" :key="key_data_dni_data">
                                                                                <th width="350">{{key_data_dni_data}}</th>
                                                                                <td>{{value_data_dni_data}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </CCardBody>
                                                            </CCollapse>
                                                        </CCard>
                                                    </div>
                                                </CTab>
                                                <CTab title="Data Checklist">
                                                    <div>
                                                        <CCard class="mb-0" v-for="(value_data_checklist, key_data_checklist) in data_data_checklist" v-bind:key="key_data_checklist">
                                                            <CButton
                                                                block
                                                                color="link"
                                                                class="text-left shadow-none card-header"
                                                                @click="accordion = accordion === key_data_checklist ? false : key_data_checklist"
                                                            >
                                                                <h5 class="m-0">Data DNI #{{key_data_checklist+1}}</h5>
                                                            </CButton>
                                                            <CCollapse :show="accordion === key_data_checklist">
                                                                <CCardBody>
                                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                        <tbody>
                                                                            <tr v-for="(value_data_checklist_data, key_data_checklist_data) in value_data_checklist" :key="key_data_checklist_data">
                                                                                <th width="350">{{key_data_checklist_data}}</th>
                                                                                <td>{{value_data_checklist_data}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </CCardBody>
                                                            </CCollapse>
                                                        </CCard>
                                                    </div>
                                                </CTab>
                                                <CTab title="Data Proyek">
                                                    <div>
                                                        <CCard class="mb-0" v-for="(value_data_proyek, key_data_proyek) in data_data_proyek" v-bind:key="key_data_proyek">
                                                            <CButton
                                                                block
                                                                color="link"
                                                                class="text-left shadow-none card-header"
                                                                @click="accordion = accordion === key_data_proyek ? false : key_data_proyek"
                                                            >
                                                                <h5 class="m-0">Data Proyek #{{key_data_proyek+1}}</h5>
                                                            </CButton>
                                                            <CCollapse :show="accordion === key_data_proyek">
                                                                <CCardBody>
                                                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                        <tbody>
                                                                            <tr v-for="(value_data_proyek_data, key_data_proyek_data) in value_data_proyek" :key="key_data_proyek_data">
                                                                                <th width="350">{{key_data_proyek_data}}</th>
                                                                                <td>
                                                                                    {{value_data_proyek_data}}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </CCardBody>
                                                            </CCollapse>
                                                        </CCard>
                                                    </div>
                                                </CTab>
                                            </CTabs>
                                        </template>
                                    </div>
                                </CTab>
                                <CTab title="KBLI Perusahaan" v-if="data_perusahaan.tipe_pemohon_id != 2">
                                    <div class="mt-3">
                                        <table class="table table-sm table-bordered">
                                            <thead class="bg-dark text-white">
                                                <tr>
                                                    <td>No</td>
                                                    <td>Kode KBLI</td>
                                                    <td>Nama KBLI</td>
                                                    <td>Kategori</td>
                                                </tr>
                                            </thead> 
                                            <tbody>
                                                <tr v-for="(row_data_kbli, key_kbli) in data_perusahaan.perusahaan_kbli" :key="key_kbli">
                                                    <td>
                                                        <!-- <template>
                                                            <div v-if="key_kbli+1 == 10">
                                                                {{curent_page}}0
                                                            </div>
                                                            <div v-else>
                                                                {{(curent_page-1 != 0)?curent_page-1:''}}{{key_perusahaan+1}}
                                                            </div>
                                                        </template> -->
                                                        {{key_kbli+1}}
                                                    </td>
                                                    <td>{{row_data_kbli.kp_kode}}</td>
                                                    <td>{{row_data_kbli.kp_nama}}</td>
                                                    <td>{{row_data_kbli.m_kategori}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </CTab>
                                <CTab title="Perbarui Data Instansi" v-if="data_perusahaan.tipe_pemohon_id == 2"> 
                                    <div class="row">										
                                        <div class="col-md-12 mt-3">
                                            <div class="form-group">
                                                <b for="">Tipe Penyelenggara <span class="text-danger">*</span></b>
                                                <input type="text" v-model="data_perusahaan.tipe_pemohon.tp_nama" disabled class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <b for="">Email Instansi Pemerintah <span class="text-danger">*</span></b>
                                                <input type="text" class="form-control" placeholder="Masukkan Email" v-model="data_perusahaan.p_email">
                                            </div>
                                            <div class="form-group">
                                                <b for="">Nama Perusahaan / Perorangan <span class="text-danger">*</span></b>
                                                <input type="text" class="form-control" placeholder="Masukkan Nama Perusahaan" v-model="data_perusahaan.p_nama">
                                            </div>
                                            <div class="form-group">
                                                <b for="">Nama Penanggung Jawab <span class="text-danger">*</span></b>
                                                <input type="text" class="form-control" placeholder="Masukkan Nama Penanggung Jawab" v-model="data_perusahaan.p_penanggung_jawab">
                                            </div>
                                            <div class="form-group">
                                                <b for="">Jenis Usaha <span class="text-danger">*</span></b>
                                                <v-select :options="master_jenis_perusahaan" label="jp_nama" multiple v-model="jenis_usaha"></v-select>
                                            </div>
                                            <div class="form-group">
                                                <b for="">Nomor Pokok Wajib Pajak (NPWP) <span class="text-danger">*</span></b>
                                                <input type="text" class="form-control" placeholder="Masukkan NPWP" v-model="data_perusahaan.p_npwp" >
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <b for="">No. Telp <span class="text-danger">*</span></b>
                                                        <input type="text" class="form-control" placeholder="Masukkan No. Telp"  v-model="data_perusahaan.p_telp">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <b for="">No. Handphone <span class="text-danger">*</span></b>
                                                        <input type="text" class="form-control" placeholder="Masukkan No. Handphone" v-model="data_perusahaan.p_hp">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <b for="">FAX <span class="text-danger">*</span></b>
                                                        <input type="text" class="form-control" placeholder="Masukkan FAX" v-model="data_perusahaan.p_fax">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <b for="">Provinsi<span class="text-danger">*</span></b>
                                                        <!-- <input type="text" :value="provinsi_selected.pr_nama" class="form-control"> -->
                                                        <v-select :options="master_provinsi" label="pr_nama" v-model="provinsi_selected" @input="kota_selected = [], kecamatan_selected = []"></v-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <b for="">Kabupaten / Kota <span class="text-danger">*</span></b>
                                                        <v-select :options="provinsi_selected.kota" label="k_nama" v-model="kota_selected" @input="kecamatan_selected = []"></v-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <b for="">Kecamatan <span class="text-danger">*</span></b>
                                                        
                                                        <v-select :options="kota_selected.kecamatan" label="kc_nama" v-model="kecamatan_selected"></v-select>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <b for="">Kode Pos <span class="text-danger">*</span></b>
                                                <input type="text" class="form-control" v-model="data_perusahaan.p_kode_pos">
                                            </div>
                                            <div class="form-group">
                                                <b>Alamat ( Sesuai NPWP ) <span class="text-danger">*</span></b>
                                                <textarea class="form-control" rows="4"  v-model="data_perusahaan.p_alamat"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-success text-center btn-block" v-on:click="updatePerusahaanRegister"><i class="fa fa-save"></i> Simpan</button>
                                        </div>
                                    </div>
                                </CTab>
                                <CTab title="Daftar Member Perusahaan"> 
                                    <div class="btn btn-primary btn-hover-outline-primary mt-3" v-on:click="modalTambahMember = true"><i class="fa fa-plus"></i> Tambah Member</div>
                                    <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                        <thead class="bg-dark text-white">
                                            <tr>
                                                <th style="width: 15px">No</th>
                                                <th>Nama</th>
                                                <th>Email</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(value_data_member, key_data_member) in data_member" v-bind:key="key_data_member">
                                                <td>{{key_data_member + 1}}</td>
                                                <td>{{value_data_member.name}}</td>
                                                <td>{{value_data_member.email}}</td>
                                                <td>
                                                    <button square="" type="button" class="btn btn-info btn-sm" v-on:click="showEditMember(value_data_member.id)"><i class="fa fa-trash"></i> Edit</button>
													<button square="" type="button" class="btn btn-danger btn-sm" v-on:click="deleteDataMember(value_data_member.id)"><i class="fa fa-trash"></i> Hapus</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CTab>
                            </CTabs>
                        </template>
                    </CCardBody>
                </CCard>
                <template>
                    <div>
                    <CModal
                        title="Tambah Member"
                        color="btn-primary"
                        :show.sync="modalTambahMember"
                    >
                    <CRow>
                        <CCol md="12">
                            <div class="form-group">
                                <b>Nama<span class="text-danger">*</span></b>
                                <input type="text" class="form-control" id="memberbaru-nama" />
                            </div>
                        </CCol>
                        <CCol md="12">
                            <div class="form-group">
                                <b>Email Pendaftar <span class="text-danger">*</span></b>
                                <input type="email" class="form-control" id="memberbaru-email" />
                            </div>
                        </CCol>
                        <CCol md="12">
                            <div class="form-group">
                                <b>Password <span class="text-danger">*</span></b>
                                <input type="password" class="form-control" id="memberbaru-password" />
                            </div>
                        </CCol>
                        <CCol md="12">
                            <div class="form-group">
                                <b>Konfirmasi Password <span class="text-danger">*</span></b>
                                <input type="password" class="form-control" id="memberbaru-confirmpassword" />
                            </div>
                        </CCol>
                    </CRow>
                    <template #footer>
                        <CButton @click="modalTambahMember = false" color="secondary">Tutup</CButton>
                        <CButton v-on:click="registerMemberProcess()" color="primary">Simpan</CButton>
                    </template>
                    </CModal>
                    </div>
                </template>
                <template>
                    <div>
                    <CModal
                        title="Tambah Member"
                        color="btn-primary"
                        :show.sync="modalEditMember"
                    >
                    <CRow>
                        <CCol md="12">
                            <div class="form-group">
                                <b>Nama<span class="text-danger">*</span></b>
                                <input type="text" class="form-control" v-model="memberbaru_nama" />
                            </div>
                        </CCol>
                        <CCol md="12">
                            <div class="form-group">
                                <b>Email Pendaftar <span class="text-danger">*</span></b>
                                <input type="email" class="form-control" v-model="memberbaru_email" />
                            </div>
                        </CCol>
                        <CCol md="12">
                            <div class="form-group">
                                <b>Password <span class="text-danger">*</span></b>
                                <input type="password" class="form-control" v-model="memberbaru_password" />
                            </div>
                        </CCol>
                        <CCol md="12">
                            <div class="form-group">
                                <b>Konfirmasi Password <span class="text-danger">*</span></b>
                                <input type="password" class="form-control" v-model="memberbaru_confirmpassword" />
                            </div>
                        </CCol>
                    </CRow>
                    <template #footer>
                        <CButton @click="modalEditMember = false" color="secondary">Tutup</CButton>
                        <CButton v-on:click="editMemberProcess()" color="primary">Simpan</CButton>
                    </template>
                    </CModal>
                    </div>
                </template>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    const dataintable = [];
    export default {
        name: "DaftarPerusahaan",
        components: {},
        data() {
            return {
                id_perusahaan: this.$route.params.id_perusahaan,
                data_perusahaan: [],
                data_infestasi: [],
                data_profile_perusahaan: [],
                data_pemegang_saham: [],
                data_penanggung_jawab: [],
                data_legalitas: [],
                data_rptka_negara: [],
                data_rptka_lokasi: [],
                data_data_dni: [],
                data_data_checklist: [],
                data_data_proyek: [],
                accordion: 0,
                data_member:[],
                modalTambahMember: false,
                modalEditMember: false,
                memberbaru_nama: '',
                memberbaru_email: '',
                memberbaru_id: '',
                memberbaru_password: '',
                memberbaru_confirmpassword: '',
                master_provinsi: '',
                provinsi_selected: [],
                
                kota_selected: [],
                kecamatan_selected: [],
                jenis_usaha: [],
                master_jenis_perusahaan: [],


            }
        },
        methods: {
            registerMemberProcess() {
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon Tunggu Sebentar.",
					showConfirmButton: false,
				});
				let self = this;
				var FormData = require('form-data');
				var data = new FormData();
				data.append('name', $("#memberbaru-nama").val());
				data.append('email', $("#memberbaru-email").val());
				data.append('password', $("#memberbaru-password").val());
				data.append('password_confirmation', $("#memberbaru-confirmpassword").val());
				data.append('nib', this.data_perusahaan.nib);
				data.append('tipe_pemohon_id', this.data_perusahaan.tipe_pemohon_id);
				data.append('perusahaan_id', this.data_perusahaan.id);
				var config = {
				  method: 'post',
				  url: this.apiLink + 'api/crud/member',
				  headers: { 
					Authorization: "Bearer " + this.access_token,
				  },
				  data : data
				};
				axios(config)
				.then((response) => {
					let timerInterval;
					Swal.fire({
						icon: "success",
						title: "Berhasil",
						text: "Data member baru berhasil disimpan",
						timer: 2000,
						timerProgressBar: true,
						showCancelButton: false,
						showConfirmButton: false,
						didOpen: () => {
							timerInterval = setInterval(() => {
								const content = Swal.getContent();
								if (content) {
									const b = content.querySelector("b");
									if (b) {
										b.textContent = Swal.getTimerLeft();
									}
								}
							}, 100);
						},
						willClose: () => {
							clearInterval(timerInterval);
						},
					}).then((result) => {
						/* Read more about handling dismissals below */
						if (result.dismiss === Swal.DismissReason.timer) {
							// console.log("I was closed by the timer");
							self.modalTambahMember =  false;
							self.modalEditMember =  false;
							self.refreshMember();
						}
					});
				})
				.catch((error) => {
					// console.log(error);
					if (error) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Terjadi Kesalahan, Coba Lagi Nanti",
						});
					}
				});
			},
            refreshMember(){
                axios
				.get(this.apiLink + "api/crud/user", {
					params: {
						perusahaan_id: this.id_perusahaan,
					},
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
				})
				.then((response) => {
					var res_user = response.data;
					var res_user_data = res_user.data[0];
					// console.log(res_user_data);
					if (res_user.meta.code != "200") {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res_user.data.message,
						});
					} else {
						this.data_member = res_user_data.data;
					}
				});
            },
            showEditMember(id_member) {
				var member = this.data_member.find(members_data => {
                    if (members_data.id === id_member) {
                        return members_data;
                    }
                });
				this.memberbaru_nama = member.name;
				this.memberbaru_email = member.email;
				this.memberbaru_id = member.id;
				console.log(this.memberbaru_nama);
				this.modalEditMember = true;
			},
            editMemberProcess: function () {
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Mohon Tunggu Sebentar.",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
				let self = this;
                axios.put(
                    this.apiLink + "api/crud/user",
                    {
						
                        id: this.memberbaru_id,
                        name:this.memberbaru_nama,
                        email:this.memberbaru_email,
                        password:this.memberbaru_password,
                        password_confirmation:this.memberbaru_confirmpassword,
						roles_id: 2,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_user.data.message
                        });
                    }
                    else{
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Data member anda berhasil disimpan",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.modalTambahMember =  false;
								self.modalEditMember =  false;
								self.refreshMember();
                            }
                        });
                    }
                });
            },
            deleteDataMember(id) {
                // alert(id);
				let self = this;
                Swal.fire({
                    title: "Apakah anda yakin?",
                    text: "Anda tidak bisa mengembalikan data yang dihapus!",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Hapus`,
                    denyButtonText: `Batal`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: '<i class="fa fa-refresh fa-spin"></i>',
                            text: "Mohon Tunggu Sebentar.",
                            showConfirmButton: false,
                        });
                        axios
                            .delete(this.apiLink + "api/crud/user", {
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: {
                                    id: id,
                                },
                            })
                            .then((response) => {
                                var res_data = response.data;
                                // console.log(res_data);
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data member berhasil dihapus.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
											self.refreshMember();
                                        }
                                    });
                                }
                            });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                });
            },
            updatePerusahaanRegister() {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon Tunggu Sebentar.",
					showConfirmButton: false,
				});
				var FormData = require("form-data");
				var data = new FormData();
				data.append('id', self.data_perusahaan.id);
				data.append('p_nama', self.data_perusahaan.p_nama);
				data.append('p_npwp', self.data_perusahaan.p_npwp);
				data.append('p_penanggung_jawab', self.data_perusahaan.p_penanggung_jawab);
				data.append('p_email', self.data_perusahaan.p_email);
				data.append('p_telp', self.data_perusahaan.p_telp);
				data.append('p_alamat', self.data_perusahaan.p_alamat);
				data.append('jenis_perusahaan_json', JSON.stringify(self.jenis_usaha) );
				data.append('perusahaan_kbli_json', JSON.stringify(self.perusahaan_kbli));
				data.append('p_hp', self.data_perusahaan.p_hp);
				data.append('p_fax', self.data_perusahaan.p_fax);
				data.append('provinsi_id', self.provinsi_selected.id);
				data.append('kota_id', self.kota_selected.id);
				data.append('kecamatan_id', self.kecamatan_selected.id);
				data.append('p_kode_pos', self.data_perusahaan.p_kode_pos);
				var config = {
					method: "post",
					url: this.apiLink + "api/crud/perusahaan/update_perusahaan",
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
					data: data,
				};
				axios(config)
					.then((response) => {
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Data Anda Berhasil Disimpan",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								// console.log("I was closed by the timer");
							}
						});
					})
					.catch((error) => {
						// console.log(error);
						if (error) {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: "Terjadi Kesalahan, Coba Lagi Nanti",
							});
						}
					});
			},
        },
        created() {
            axios.get(this.apiLink + "api/crud/perusahaan/detail_perusahaan", {
                params:{
                    id: this.id_perusahaan
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_perusahaan = response.data;
                
                if (res_perusahaan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_perusahaan.data.message
                    });
                }
                else{
                    this.data_perusahaan = res_perusahaan.data[0];
                    console.log(this.data_perusahaan);
                    if(this.data_perusahaan?.tipe_pemohon_id == 2){
						this.provinsi_selected = this.data_perusahaan.provinsi;
                        console.log(this.provinsi_selected);
						this.kecamatan_selected = this.data_perusahaan.kecamatan;
						this.kota_selected = this.data_perusahaan.kota;
						this.jenis_usaha = JSON.parse(this.data_perusahaan.jenis_perusahaan_json);
					}
                    if(this.data_perusahaan.tipe_pemohon_id != 2){
                        var datanibperusahaan = JSON.parse(this.data_perusahaan.p_json).responinqueryNIB.dataNIB;
                        var clear_data_perusahaan = {};
                        $.each(datanibperusahaan, function (index, val) {
                            if ($.isArray(val) || val instanceof jQuery) {
                                } else {
                                    clear_data_perusahaan[index] = val;
                            }
                        });
                        this.data_profile_perusahaan = clear_data_perusahaan;
                        this.data_pemegang_saham = datanibperusahaan.pemegang_saham;
                        this.data_penanggung_jawab = datanibperusahaan.penanggung_jwb;
                        this.data_legalitas = datanibperusahaan.legalitas;
                        this.data_rptka_negara = datanibperusahaan.data_rptka.rptka_negara;
                        this.data_rptka_lokasi = datanibperusahaan.data_rptka.rptka_lokasi;
                        this.data_data_dni = datanibperusahaan.data_dni;
                        this.data_data_checklist = datanibperusahaan.data_checklist;
                        this.data_data_proyek = datanibperusahaan.data_proyek;
                    }
                }
            });
            // Get Member
			axios
				.get(this.apiLink + "api/crud/user", {
					params: {
						perusahaan_id: this.id_perusahaan,
					},
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
				})
				.then((response) => {
					var res_user = response.data;
					var res_user_data = res_user.data[0];
					// console.log(res_user_data);
					if (res_user.meta.code != "200") {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res_user.data.message,
						});
					} else {
						this.data_member = res_user_data.data;
					}
				});
            axios.get(this.apiLink + "api/master/chain_daerah", {
				
			})
			.then((response) => {
				var res_master_daerah = response.data;
				var res_master_daerah_data = res_master_daerah.data;
				if (res_master_daerah.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_master_daerah_data.data.message
					});
				}
				else{
					this.master_provinsi = res_master_daerah_data.master;
					console.log(this.master_provinsi);
				}
			});
            axios.get(this.apiLink + "api/master/jenis_perusahaan", {
			})
			.then((response) => {
				var res_master_jenis_perusahaan = response.data;
				var res_master_jenis_perusahaan_data = res_master_jenis_perusahaan.data;
				if (res_master_jenis_perusahaan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_master_jenis_perusahaan.data.message
					});
				}
				else{
					this.master_jenis_perusahaan = res_master_jenis_perusahaan_data.master;
				}
			});
        },
    };
</script>